import React, { useEffect, useState } from "react";
import Section from "./Section";
import { useSelector } from "react-redux";
import "./playground.css";
import playgroundMapping from "../../mappings/playgroundMapping";

function Playground() {
  const pagesData = useSelector((state) => state.pagesDataReducer.pagesData);
  const [playgroundData, setPlaygroundData] = useState({});
  const [textData, setTextData] = useState({});
  const [form, setForm] = useState({});
  const [enumData, setEnumData] = useState({});
  const [infoForm, setInfoForm] = useState({});
  const [profileImage, setProfileImage] = useState({});
  const [multiMedia, setMultiMedia] = useState({});
  const [textTitle, setTextTitle] = useState({});
  const [textDesc, setTextDesc] = useState({});
  const [textPhone, setTextPhone] = useState({});
  const [formEmail, setFormEmail] = useState({});
  const [formPassword, setFormPassword] = useState({});
  const [enumeration, setEnumeration] = useState({});
  const [enumArray, setEnumArray] = useState({});
  const [infoName, setInfoName] = useState({});
  const [infoCountry, setInfoCountry] = useState({});
  const [infoHobbies, setInfoHobbies] = useState({});
  const [infoGender, setInfoGender] = useState({});
  const [infoAddress, setInfoAddress] = useState({});

  useEffect(() => {
    for (let item of pagesData) {
      if (item.name === "Playground") setPlaygroundData(item);
    }
  }, [pagesData]);

  useEffect(() => {
    if (playgroundData.componentData) {
      for (let item of playgroundData.componentData) {
        if (item.id === playgroundMapping.textComponents.id) setTextData(item);
        if (item.id === playgroundMapping.formComponents.id) setForm(item);
        if (item.id === playgroundMapping.enumAndArray.id) setEnumData(item);
        if (item.id === playgroundMapping.infoForm.id) setInfoForm(item);
        if (item.id === playgroundMapping.profileImage.id)
          setProfileImage(item);
        if (item.id === playgroundMapping.sampleImages.id) setMultiMedia(item);
      }
    }
  }, [playgroundData]);

  useEffect(() => {
    if (textData.childComponents) {
      for (let item of textData.childComponents) {
        if (item.id === playgroundMapping.textComponents.title.id)
          setTextTitle(item);
        if (item.id === playgroundMapping.textComponents.description.id)
          setTextDesc(item);
        if (item.id === playgroundMapping.textComponents.phoneNumber.id)
          setTextPhone(item);
      }
    }
  }, [textData]);

  useEffect(() => {
    if (form.childComponents) {
      for (let item of form.childComponents) {
        if (item.id === playgroundMapping.formComponents.email.id)
          setFormEmail(item);
        if (item.id === playgroundMapping.formComponents.password.id)
          setFormPassword(item);
      }
    }
  }, [form]);

  useEffect(() => {
    if (enumData.childComponents) {
      for (let item of enumData.childComponents) {
        if (item.id === playgroundMapping.enumAndArray.enum.id)
          setEnumeration(item);
        if (item.id === playgroundMapping.enumAndArray.array.id)
          setEnumArray(item);
      }
    }
  }, [enumData]);

  useEffect(() => {
    if (infoForm.childComponents) {
      for (let item of infoForm.childComponents) {
        if (item.id === playgroundMapping.infoForm.name.id) setInfoName(item);
        if (item.id === playgroundMapping.infoForm.country.id)
          setInfoCountry(item);
        if (item.id === playgroundMapping.infoForm.hobbies.id)
          setInfoHobbies(item);
        if (item.id === playgroundMapping.infoForm.gender.id)
          setInfoGender(item);
        if (item.id === playgroundMapping.infoForm.address.id)
          setInfoAddress(item);
      }
    }
  }, [infoForm]);

  // function transformPageData(pageData) {
  //   const { _id, componentData } = pageData;  // Use the correct `id` field here

  //   // Initialize the result object with the page's id
  //   const result = { _id };

  //   // Recursive function to process components
  //   function processComponent(component) {
  //     const { id: componentId, label, childComponents } = component;

  //     // Initialize each component with its id
  //     const componentResult = { id: componentId };

  //     // If there are child components, process them recursively
  //     if (Array.isArray(childComponents)) {
  //       childComponents.forEach((child) => {
  //         const childResult = processComponent(child);  // Recursively process child components
  //         componentResult[child.label] = childResult;   // Add child result to parent
  //       });
  //     }

  //     return componentResult;
  //   }

  //   // Check if componentData exists and is an array
  //   if (Array.isArray(componentData)) {
  //     // Iterate through each component in the componentData array
  //     componentData.forEach((component) => {
  //       const { label, id: componentId } = component;  // Use `id` instead of `_id`
  //       result[label] = { id: componentId };

  //       // Process child components if they exist
  //       if (Array.isArray(component.childComponents)) {
  //         component.childComponents.forEach((child) => {
  //           result[label][child.label] = processComponent(child);
  //         });
  //       }
  //     });
  //   } else {
  //     console.error("componentData is either undefined or not an array");
  //   }

  //   return result;
  // }

  return (
    <React.Fragment>
      <Section />
      <div className="playground-parent">
        <div className="profileImage">
          <img
            className="profile-image"
            src={profileImage.files && profileImage.files[0].url}
          />
        </div>

        <div className="text-comp">
          <h1 className="text-comp-title">Text Component</h1>
          <h2>{textTitle.value}</h2>
          <p>{textDesc.value}</p>
          <b>Phone No. : </b> <p>{textPhone.value}</p>
        </div>

        <div className="credentials-comp">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <h1 className="credentials-comp-title">Form</h1>
            <b>Email </b>
            <input
              type="email"
              placeholder={formEmail && formEmail.placeholder}
              value={formEmail && formEmail.defaultValue}
              required={formEmail && formEmail.isRequired}
              maxLength={formEmail && formEmail.maximumLength}
            />
            <br />
            <b>Password </b>
            <input
              type="password"
              placeholder={formPassword && formPassword.placeholder}
              value={formPassword && formPassword.defaultValue}
              required={
                formPassword && formPassword.isRequired == true ? true : false
              }
              maxLength={formPassword && formPassword.maximumLength}
              minLength={formPassword && formPassword.minimumLength}
            />
            <br />
            <button type="submit">Submit</button>
          </form>
        </div>

        <div className="info-form">
          <h1>Information Form</h1>
          <form>
            <div className="info-inputs">
              <div className="name-input">
                <b>{infoName && infoName.label}</b>
                <input
                  type={
                    infoName && infoName.componentName === "Short Text"
                      ? "text"
                      : null
                  }
                  placeholder={infoName && infoName.placeholder}
                  required={
                    infoName && infoName.isRequired == true ? true : false
                  }
                  value={infoName && infoName.defaultValue}
                  minLength={infoName && infoName.minimumLength}
                  maxLength={infoName && infoName.maximumLength}
                  pattern={infoName && infoName.regExp}
                />
              </div>

              <b>{infoCountry && infoCountry.label}</b>
              <div className="country-dropdown">
                <select
                  required={
                    infoCountry && infoCountry.isRequired == true ? true : false
                  }
                  value={infoCountry && infoCountry.defaultValue}
                >
                  {infoCountry.options &&
                    infoCountry.options.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                </select>
              </div>

              <div className="hobbies-checkbox">
                <b>{infoHobbies && infoHobbies.label}</b>
                {infoHobbies.options &&
                  infoHobbies.options.map((option, index) => (
                    <label key={index}>
                      <input
                        type={
                          infoHobbies && infoHobbies.componentName.toLowerCase()
                        }
                        required={
                          infoHobbies && infoHobbies.isRequired == true
                            ? true
                            : false
                        }
                      />
                      {option}
                    </label>
                  ))}
              </div>

              <div className="gender-radio">
                <b>{infoGender && infoGender.label}</b>
                {infoGender.options &&
                  infoGender.options.map((option, index) => (
                    <label key={index}>
                      <input
                        type="radio"
                        name="gender-radio"
                        value={option}
                        required={
                          infoForm.childComponents && infoGender.isRequired
                        }
                      />
                      {option}
                    </label>
                  ))}
              </div>

              <div className="address-textarea">
                <b>{infoAddress && infoAddress.label}</b>
                <br />
                <textarea
                  required={
                    infoAddress && infoAddress.isRequired == true ? true : false
                  }
                  placeholder={infoAddress && infoAddress.placeholder}
                  value={infoAddress && infoAddress.defaultValue}
                  minLength={infoAddress && infoAddress.minimunLength}
                  maxLength={infoAddress && infoAddress.maximumLength}
                />
              </div>
            </div>
            <button type="subtmit">Submit</button>
          </form>
        </div>

        <div className="enum-comp">
          <h1 className="enum-comp-title">
            Enumeration Component
            {enumData.allowMultiple == true
              ? " (Multiple Alloweed)"
              : " (Multiple Not Allowed)"}
          </h1>
          <h3>All Options :</h3>
          <div className="all-options">
            {enumeration.options &&
              enumeration.options.map((item, index) => (
                <div className="all-enum-options">{item}</div>
              ))}
          </div>
          <h3>Selected Options :</h3>
          <div className="selected-options">
            {enumeration.value &&
              enumeration.value.map((item, index) => (
                <div className="selected-enum-options">{item}</div>
              ))}
          </div>
        </div>

        <h1 className="multi-media-title">Multi Media</h1>
        <div className="multi-media-comp">
          {multiMedia.files &&
            multiMedia.files.map((file, index) => (
              <div className="multi-media-file">
                <img src={file.url} alt={file.name} />
              </div>
            ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Playground;
