import { createContext, useEffect, useState } from "react";
import magicJson from "../magicJson/magicJson";
import Section from "./Home/Layout2/Section";
import Home from "./Home/Home";
import ApiRequest from "../utils/apiRequest";

const apiRequest = new ApiRequest();

const UserContext = createContext();

const getPageMapping = async (pageId) => {
  magicJson.endpoint = "contentTypeBuilder";
  magicJson.executor = "getPageMapping";
  magicJson.data = [{ pageId }];
  
  const result = await apiRequest.sendRequest(magicJson);
  return result.data[0];
};

const getcomponents = async (token, idtosent) => {
  const _id = idtosent;
  magicJson.endpoint = "contentManager";
  magicJson.executor = "getCollectionItems";
  magicJson.data = { _id };

  const resp = await apiRequest.sendRequest(magicJson);
  if (resp) {
    return resp.data;
  } else {
    return null;
  }
};

function Alldata() {
  const [filteredrecentData, setfilteredrecentData] = useState([]);
  // const [components, setComponents] = useState([]);

  const getFields = async () => {
    magicJson.endpoint = "contentTypeBuilder";
    magicJson.executor = "getPages";
    magicJson.filter = {};

    const resp = await apiRequest.sendRequest(magicJson);
    if (resp) {
      setfilteredrecentData(resp.data[0]);
      localStorage.setItem("pagetitle", resp.data[0][0]?.name);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    // getcomponents(token, idtosent).then(data => {
    //   setComponents(data);
    // });
    getFields();
  }, []);

  const name = "demo data";

  return (
    <UserContext.Provider value={{ filteredrecentData, name }}>
      {/* <Section /> */}
      <Home />
    </UserContext.Provider>
  );
}

export { UserContext, getcomponents, getPageMapping };
export default Alldata;
