import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import jobsPageMappping from "../../../mappings/jobsPageMapping";

const JobType = ({ jobVacancyList, onJobTypeChange }) => {
  const pagesData = useSelector((state) => state.pagesDataReducer.pagesData);
  const { id, skillsDropdown } = jobsPageMappping;
  const [jobsPageData, setJobsPageData] = useState({});
  const [skillsDropdownOptions, setSkillsDropdownOptions] = useState([]);
  const [skillsObj, setSkillsObj] = useState([]);

  const skillSet = new Set();
  if (jobVacancyList?.length) {
    jobVacancyList.forEach((job) => {
      job.skills.forEach((skill) => {
        skillSet.add(skill);
      });
    });
  }

  useEffect(() => {
    const jobsPageData = pagesData.find((item) => item._id === id);
    setJobsPageData(jobsPageData);
  }, [pagesData]);

  useEffect(() => {
    if (jobsPageData?.componentData) {
      const skillsOptions = jobsPageData.componentData.find(
        (item) => item.id === skillsDropdown.id
      )?.options;
      setSkillsDropdownOptions(skillsOptions);
    }
  }, [jobsPageData]);

  useEffect(() => {
    let arr = [];
    for (let i = 0; i < skillsDropdownOptions?.length; i++) {
      arr.push({ value: "" + i, label: skillsDropdownOptions[i] });
    }
    arr.shift();
    arr.unshift({ label: "All skills", value: "all" });
    setSkillsObj(arr);
  }, [skillsDropdownOptions]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const options = Array.from(skillSet).map((skill, index) => ({
    label: capitalizeFirstLetter(skill),
    value: index.toString(),
  }));

  options.unshift({ label: "All skills", value: "all" });

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: 0,
      boxShadow: "none",
      padding: "12px 0 12px 40px",
      margin: "-16px -6px 0 -52px",
      borderRadius: "0",
    }),
  };

  const handleChange = (selectedOption) => {
    onJobTypeChange(selectedOption);
  };

  return (
    <React.Fragment>
      <Select
        options={skillsObj}
        styles={colourStyles}
        className="selectForm__inner"
        data-trigger
        defaultValue={{ label: "All skills", value: "all" }}
        name="choices-single-categories"
        id="choices-single-categories"
        aria-label="Default select example"
        onChange={handleChange}
      />
    </React.Fragment>
  );
};

export default JobType;
